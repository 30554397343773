function fdate(va) { // 日期换,xx月xx日
    let date = new Date(va);
    return date.getMonth() + 1 + "月" + date.getDate() + "日"
}
function fdate2(va) { // 截取日期
    if (!va) return ""
    return va.slice(0, 10)
}

function albumstype(va) {
    va = Number(va)
    switch (va) {
        case 1: return "照片";
        case 2: return "视频";
        case 3: return "3D照片";
        case 4: return "3D视频";
    }
}


//*姓名脱敏
function tmName(val) {
    if (!val) return;
    val = val.replace(/^\s+|\s+$/g, "");
    if (val && val.length == 2) {
        return val.replace(/^(.{1}).*(.{1})$/, "$1*");
    } else if (val && val.length > 2) {
        return val.replace(/^(.{1}).*(.{1})$/, "$1***$2");
    } else {
        return val;
    }
}

export default {
    tmName, fdate, fdate2, albumstype
};