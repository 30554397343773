<template>
	<div class="layout">
		<div class="navber">
			<div class="content flex_r flex_jb">
				<div class="logo flex_r flex_ac">
					<img src="@/assets/img/home/img4.png">
					<div class="name">LeSmart</div>
				</div>
				<div class="flex_r flex_ac">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane v-for="i, s in tabs" :key="s" :label="i.label" :name="i.name" />
					</el-tabs>
					<div class="tab">
						<div class="title">立即购买</div>
						<div class="options flex_c flex_jc">
							<div @click="to(i.url)" class="shop flex_r flex_ac flex_jc" v-for="i, s in shops" :key="s">
								<img :src="i.ico" class="ico">
								<div class="name">{{ i.name }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<router-view />
	</div>
</template>

<script>
import { EventBus } from '@/utils/eventBus';
export default {
	name: "layout",
	data() {
		return {
			tabs: [
				{ label: "首页", name: "home" },
				{ label: "产品中心", name: "product" },
				{ label: "技术支持", name: "support" },
			],
			activeName: "home",
			shops: [
				{ name: "淘宝", ico: require("@/assets/img/layout/taobao.png"), url: "https://shop395576459.taobao.com/?spm=a230r.7195193.1997079397.2.40d92c702dInCl" },
				{ name: "抖音", ico: require("@/assets/img/layout/douyin.png"), url: "https://v.douyin.com/iA3GgoUM/" },
				{ name: "京东", ico: require("@/assets/img/layout/jingdong.png"), url: "https://mall.jd.com/index-16454217.html" }
			]
		};
	},
	created() {
		this.activeName = this.$route.name;
	},
	mounted() {
		EventBus.$on('changeTab', this.updateLayoutData);
	},
	methods: {
		handleClick(e) {

			if (e.name) this.$router.push(e.name, () => { }, () => { })
		},
		to(url) {
			window.open(url, "_blank")
		},
		updateLayoutData(newValue) {
			this.activeName = newValue
		}
	},
};
</script>

<style lang="scss" scoped>
.navber {
	width: 100%;
	height: 40px;
	background-color: #222;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 99;

	.logo {
		height: 40px;

		img {
			width: 20px;
			height: 30px;
			margin-right: 16px;
		}

		.name {
			color: #fff;
			font-weight: bold;
			font-size: 14px;
		}
	}

	.tab {
		color: #fff;
		font-size: 12px;
		padding: 1px 0 0 36px;
		position: relative;

		&:hover {
			color: #409EFF;
			cursor: pointer;

			.options {
				height: 118px;
				transition: height 0.5s;
			}
		}

		.title {
			height: 40px;
			line-height: 40px;
		}

		.options {
			color: #333;
			position: absolute;
			right: -20px;
			background: #fff;
			border-radius: 8px;
			width: 100%;
			text-align: center;
			height: 0;
			transition: height 0.5s;
			overflow: hidden;

			.shop {
				padding: 8px 0;
				font-size: 15px;

				&:hover {
					color: #000;
					background-color: rgba($color: #000, $alpha: 0.1);
				}
			}

			.ico {
				width: 20px;
				height: 20px;
				margin-right: 6px;
			}
		}
	}
}

::v-deep .el-tabs {
	display: inline-block;
	background-color: #222;

	.el-tabs__item {
		color: #fff;

		&.is-active {
			color: #409EFF;
		}
	}

	.el-tabs__header {
		margin: 0;
	}

	.el-tabs__nav-wrap::after {
		background-color: #222;
	}
}
</style>