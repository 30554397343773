<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
	name: "",
	data() {
		return {};
	},
	created() {},
	mounted() {},
	methods: {},
};
</script>

<style lang="scss" scoped>
</style>
