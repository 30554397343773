import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import "@/assets/css/common.scss"

// 全局注册过滤
import filters from "@/utils/filters.js";
Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key]);
});

import { EventBus }   from '@/utils/eventBus';
Vue.prototype.$EventBus = EventBus;
// 全局注册自定义指令
import directives from "@/utils/directive.js";
Object.keys(directives).forEach(key => {
	Vue.directive(key, directives[key]);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
