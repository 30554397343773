let focus = {
    inserted(el) {
        el.focus()
    }
}

let getWidth = {
    inserted(el, binding) {
        const width = el.offsetWidth;
        binding.value(width);
    }
}

export default {
    focus, getWidth
};