import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/views/layout/index.vue'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/home',
		component: layout,
		children: [
			{
				path: '/home',
				name: 'home',
				component: HomeView
			},
			{
				path: '/product',
				name: 'product',
				component: () => import('@/views/productCenter/index.vue')
			},
			{
				path: '/support',
				name: 'support',
				component: () => import('@/views/support.vue')
				
			},
		]
	},
	{
		path: '/agreement',
		name: 'agreement',
		component: () => import('@/views/agreement/index.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
