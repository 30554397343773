<template>
    <div class="footbar">
        <div class="content">
            <div class="box flex_r flex_jb">
                <div class="logo">
                    <img src="@/assets/img/home/img4.png" />
                    <div class="txt">LeSmart</div>
                </div>
                <div class="heading flex_r flex_jb">
                    <div class="item">
                        <div class="tit">产品介绍</div>
                        <div class="txt" @click="togo('product')">3D时光机</div>
                    </div>
                    <div class="item">
                        <div class="tit">技术支持</div>
                        <div class="txt" @click="togo('support')">时光立方</div>
                    </div>
                    <div class="item">
                        <div class="tit">联系我们</div>
                        <div class="txt" @click="toEmail('home')">预约交流</div>
                    </div>
                </div>
                <div class="qrcodes flex_r flex_jb">
                    <div class="item">
                        <img src="@/assets/img/foot/down_app.png">
                        <div class="txt">扫码下载APP</div>
                    </div>
                    <div class="item">
                        <img src="@/assets/img/foot/mp-weixin.png">
                        <div class="txt">扫码进入小程序</div>
                    </div>
                </div>
            </div>
            <div class="infos">
                <div class="p1 flex_r flex_ac flex_jc">
                    <div class="txt">Copyighte 2024 LeSmart reserved</div>
                    <div class="txt">隐私协议</div>
                    <div class="txt">用户协议</div>
                </div>
                <div class="p1">粤ICP备2023152195号-1 粤公网备案</div>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/utils/eventBus';
export default {
    name: "footbar",
    data() {
        return {};
    },
    created() { },
    mounted() { },
    methods: {
        togo(name) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // 平滑滚动
            });
            EventBus.$emit('changeTab', name);
            this.$router.push(name, () => { }, () => { })
        },
        toEmail(name) {

            EventBus.$emit('changeTab', name);
            EventBus.$emit('toEmail', name);

            this.$router.push(name, () => { }, () => { })
        },
    },
};
</script>

<style lang="scss" scoped>
.footbar {
    width: 100%;
    padding: 36px 0;
    background: #000;
    color: #fff;

    .box {
        .logo {
            img {
                width: 62px;
                height: 98px;
            }

            .txt {
                font-size: 16px;
                font-weight: 600;
                margin-top: 8px;
            }
        }

        .heading {
            .item {
                margin: 0 30px;
                text-align: center;
            }

            .tit {
                font-size: 18px;
            }

            .txt {
                font-size: 14px;
                color: #748EA6;
                line-height: 50px;
            }
        }

        .qrcodes {
            .item {
                margin: 0 30px;
            }

            img {
                width: 108px;
                height: 108px;
            }

            .txt {
                text-align: center;
                font-size: 14px;
                margin-top: 8px;
            }
        }
    }

    .infos {
        color: #748EA6;
        text-align: center;
        font-size: 12px;
        line-height: 22px;
        margin-top: 30px;

        .txt {
            margin: 0 6px;
        }
    }
}
</style>